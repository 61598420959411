import { useConfigProvider } from '@/context/ConfigProvider';
import { useUserProvider } from '@/context/UserProvider';
import {
  IWebsiteAddReqBody,
  IWebsiteLinksResBody,
  websiteAdd,
  websiteLinks,
} from '@/lib/service/instantknow';
import { commonImgPath } from '@/shared/app-common';
import { isValidURL } from '@/shared/utils';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { isEmpty } from 'lodash-es';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import CommonSimpleModal from './CommonSimpleModal';

const LoadingCreate = dynamic(() => import('../Dashboard/DashboardList/LoadingCreate'), {
  ssr: false,
});

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 880px; /* 设置宽度为 880px */
  height: 540px; /* 设置高度为 540px */
  background-color: white;
  display: flex;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  overflow: hidden;
  padding: 12px;
`;

const LeftDiv = styled.div`
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  flex: 1;
  background-image: url(${() => `${commonImgPath}/enter_website_bg.webp`});
  background-size: cover;

  padding: 20px;
  position: relative;

  > .webicon {
    position: absolute;
    left: 20px;
    top: 20px;
    border-radius: 9px;
    background: #ffffff;
  }

  > .leftContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > .title {
      font-family: Inter, Inter;
      font-weight: normal;
      font-size: 20px;
      color: #151b26;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    > .desc {
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 14px;
      color: #5a607f;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
`;

const RightDiv = styled.div<{
  currentIndex: number;
}>`
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  flex: 1;
  background-color: #f3f4f8;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;

  > .rightTitleContent {
    position: ${(props) => (props.currentIndex !== 3 ? 'absolute' : undefined)};
    top: 32px;
    left: 20px;
    right: 20px;

    > .title {
      font-family: Inter, Inter;
      font-weight: normal;
      font-size: 20px;
      color: #151b26;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    > .desc {
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 14px;
      color: #5a607f;
      line-height: 21px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

  > .otherContent {
    flex-grow: 1;
    margin-top: ${(props) => (props.currentIndex !== 3 ? '45%' : '10px')};
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .midContent {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      > .searchContent {
        padding: 8px 12px;
        height: 48px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #dfe2ee;
        display: ${(props) => (props.currentIndex !== 3 ? 'flex' : 'none')};
        align-items: center;

        > input {
          flex: 1;
          height: 100%;
          padding: 0;
          border: none;
          background: #fff;
          color: #000;

          &::placeholder {
            color: #bac3d0;
          }
          &:focus {
            outline: none;
          }
        }

        > .btn {
          margin-left: 10px;
          width: 84px;
          background: linear-gradient(270deg, #7141f9 0%, #1e85ff 100%);
          border-radius: 6px;

          font-family: Inter, Inter;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
      }

      .itemList {
        flex-grow: 1;
        max-height: 320px; /* 设置 max-height 或根据需要调整 */
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        .item {
          padding: 10px;
          border: 1px solid #dfe2ee;
          flex-shrink: 0;
          border-radius: 8px;
          margin-top: 12px;
          background: #fff;
          cursor: pointer;
          font-family: Inter, Inter;
          font-weight: 400;
          font-size: 14px;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          line-height: 1.2;

          display: flex;
          align-items: center;
          gap: 8px;

          &:hover {
            > span {
              color: #1e5eff;
              text-align: left;
              font-style: normal;
              text-decoration-line: underline;
              text-transform: none;
            }
          }
        }
      }
    }

    > .bottomContent {
      display: flex;
      justify-content: space-between;

      > span {
        font-family: Inter, Inter;
        font-weight: 400;
        font-size: 14px;
        color: #1e5eff;
        text-align: left;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
      }

      > .btnGroup {
        display: flex;
        align-items: center;
        gap: 8px;

        > .btn {
          min-height: 38px;
          font-size: 14px;

          &:last-child {
            color: #fff;
            background: linear-gradient(270deg, #7141f9 0%, #1e85ff 100%);
          }
        }
      }
    }
  }
`;

const CloseButton = styled(CloseOutlined)`
  z-index: 999;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const GreenCheckbox = styled(Checkbox)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: green;
    border-color: green;
  }
  .ant-checkbox-checked .ant-checkbox-inner:hover {
    background-color: darkgreen;
    border-color: darkgreen;
  }
  .ant-checkbox-checked .ant-checkbox-inner:focus {
    background-color: lightgreen;
    border-color: lightgreen;
  }
`;

const EnterWebsiteModal: React.FC<{
  defaultValue?: string;
  onClose?: (isOk?: boolean) => void;
}> = ({ defaultValue, onClose }) => {
  const { isLogin, onOpenLoginModal } = useUserProvider();
  const [currentIndex, setCurrentIndex] = useState(1);
  const [searchVal, setSearchVal] = useState<string>(defaultValue);
  const { setRefreshMenu } = useConfigProvider();
  // useEffect(() => {
  //     onResetAction()
  // }, []);
  const [searchRes, setSearchRes] = useState<IWebsiteLinksResBody>(null);
  const [selectedLinks, setSelectedLinks] = useState<any[]>([]);
  const [checkedState, setCheckedState] = useState<boolean[]>([]);
  const [isStarting, setIsStarting] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (searchRes?.links) {
      setCheckedState(searchRes.links.map((_, index) => index === 0));
    }
  }, [searchRes]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isEmpty(searchVal)) {
        setSearchVal(defaultValue);
        onSearchClick();
      }
    }, 10);
    return () => clearTimeout(timer);
  }, [defaultValue]);
  useEffect(() => {
    onResetAction();
  }, []);

  const getLeftContentData = useMemo(() => {
    if (currentIndex === 2) {
      return {
        title: 'Stay Updated with Email Alerts',
        desc: "Receive email notifications whenever updates are found; we'll inform you of the specific changes made.",
      };
    } else if (currentIndex === 3) {
      return {
        title: 'Stay Ahead in the Market: Anticipate Trends and Seize Opportunities',
        desc: 'Staying ahead enables you to anticipate market trends and make informed decisions, while identifying opportunities helps you discover gaps in the market and seize potential business prospects. By analyzing your competitors, you can refine your strategy, ensuring that you remain in a favorable position in an ever-changing marketplace.',
      };
    } else {
      return {
        title: 'Looking for Instant Changes?',
        desc: 'Get notified of changes on any website—easily keep track of everything important to you on the web. Never Miss a Change Again! Our simple setup allows you to monitor any webpage in just a few clicks—Start your free trial now!',
      };
    }
  }, [currentIndex]);

  const getRightContentData = useMemo(() => {
    if (currentIndex === 2) {
      return {
        title: 'Task In Progress...',
        desc: 'Please hold on a moment. This link analysis process starts by identifying the main website for monitoring, then collects all relevant sublinks to understand its structure, and finally filters out any irrelevant or broken links to ensure accurate and effective monitoring.',
      };
    } else if (currentIndex === 3) {
      return {
        title: 'Select monitoring links',
        desc: 'We have analyzed the valid links under this domain for you. You can now select the links you wish to monitor.',
      };
    } else {
      return {
        title: 'Enter the website',
        desc: 'Enter a link to the website you wish to monitor. We recommend using the domain name. We will automatically detect sub-links for you!',
      };
    }
  }, [currentIndex]);

  const onSearchClick = async () => {
    // console.log(searchVal);
    if (!isValidURL(searchVal)) {
      return message.error(`Please enter a valid URL.`);
    }
    setCurrentIndex(2);

    try {
      websiteLinks(searchVal)
        .then((res) => {
          if (res?.code === 0) {
            setSearchRes(res?.data);
            setSelectedLinks(res?.data?.links ? [res?.data?.links[0]] : []);
            setCurrentIndex(3);
          } else if (res.code === 50001) {
            setShowError(true);
          } else {
            message.error(res?.message);
            onResetAction();
          }
        })
        .catch((e) => {
          message.error(e?.message);
          onResetAction();
        });
    } catch (error) {
      onResetAction();
    }
  };

  const onResetAction = () => {
    setCurrentIndex(1);
    // setSearchVal('');
  };

  const onStartMonitoringAction = async () => {
    if (selectedLinks.length === 0 || !searchRes) {
      return message.error(`Please select the link to monitor`);
    }
    setIsStarting(true);
    const websiteAddReq: IWebsiteAddReqBody = {
      url: searchRes.url,
      links: selectedLinks,
      title: searchRes.title,
      logo: searchRes.logo,
    };
    try {
      const res = await websiteAdd(websiteAddReq);
      if (res.code === 0) {
        message.success(`Start monitoring successfully`);
        setRefreshMenu(new Date().getTime().toString());
        if (onClose) {
          onClose(true);
        }
      } else {
        message.error(`Start monitoring failed: ${res.message}`);
      }
    } catch (error) {
      message.error(`Start monitoring failed`);
    } finally {
      setIsStarting(false);
    }
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent, item: any, index: number) => {
    if (e.target.checked) {
      if (!isLogin && selectedLinks?.length >= 20) {
        return message.error(`Unauthenticated users can select up to 20 links.`);
      }
      setSelectedLinks([...selectedLinks, item]);
    } else {
      const newSelectedLinks = selectedLinks.filter((link) => link.url !== item.url);
      setSelectedLinks(newSelectedLinks);
    }

    // Update the checked state
    const newCheckedState = [...checkedState];
    newCheckedState[index] = e.target.checked;
    setCheckedState(newCheckedState);
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton
          onClick={() => {
            onClose && onClose(false);
            setRefreshMenu(new Date().getTime().toString());
          }}
        />

        <LeftDiv>
          <Image
            src={'/images/layouts/instantknow.ai-logo.webp'}
            alt={''}
            width={54}
            height={54}
            className="webicon"
          />

          <div className="leftContent">
            <div className="title">{getLeftContentData.title}</div>
            <div className="desc">{getLeftContentData.desc}</div>
          </div>
        </LeftDiv>

        <RightDiv currentIndex={currentIndex}>
          <div className="rightTitleContent">
            <div className="title">{getRightContentData.title}</div>
            <div className="desc">{getRightContentData.desc}</div>
          </div>

          <div className="otherContent">
            <div className="midContent">
              <div className="searchContent">
                <input
                  disabled={currentIndex === 2}
                  type="text"
                  placeholder={`EX: www.google.com`}
                  defaultValue={searchVal}
                  value={searchVal}
                  onChange={(e) => setSearchVal(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onSearchClick();
                    }
                  }}
                />
                <Button
                  className="btn"
                  onClick={() => onSearchClick()}
                  loading={currentIndex === 2}
                >
                  {`GO >`}
                </Button>
              </div>
              {currentIndex === 2 && <LoadingCreate />}
              {currentIndex === 3 && (
                <>
                  <div>{`${selectedLinks?.length}/${searchRes?.links?.length || 0}`}</div>
                  <div className="itemList">
                    {searchRes?.links?.map((item, index) => {
                      return (
                        <div className="item" key={index}>
                          <GreenCheckbox
                            // defaultChecked={index === 0}
                            checked={checkedState[index]}
                            onChange={(e) => handleCheckboxChange(e, item, index)}
                          />
                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.url}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="bottomContent">
              {!isLogin ? (
                <span
                  onClick={() => onOpenLoginModal(true)}
                >{`Already have an account? Log in >`}</span>
              ) : (
                <div />
              )}
              {currentIndex === 3 && (
                <div className="btnGroup">
                  <Button className="btn" onClick={() => onResetAction()}>{`Previous`}</Button>
                  <Button
                    className="btn"
                    onClick={() => onStartMonitoringAction()}
                    loading={isStarting}
                  >{`Start Monitoring`}</Button>
                </div>
              )}
            </div>
          </div>
        </RightDiv>
      </ModalContent>
      {showError && (
        <CommonSimpleModal
          open={showError}
          title={'Failed to Get Link'}
          desc={
            <div style={{ marginTop: '15px', marginBottom: '30px', textAlign: 'center' }}>
              The URL <span style={{ color: '#1E5EFF' }}>{searchVal}</span> analysis failed,
              possibly due to a timeout or site restrictions. You can try again or replace the link.
            </div>
          }
          btnTitle={'Create Again'}
          btnCancel={'Cancel'}
          closeAction={(isOk) => {
            setShowError(false);
            setIsStarting(false);
            setCurrentIndex(1);
          }}
        />
      )}
    </ModalWrapper>
  );
};

export default EnterWebsiteModal;
