import { Button, Form, Modal } from 'antd';
import styles from './index.module.less';
import classNames from 'classnames';

const CommonSimpleModal = ({
  title,
  desc,
  btnTitle,
  btnCancel,
  rowDirection = true,
  open,
  closeAction,
}: {
  title?: string | JSX.Element;
  desc?: string | JSX.Element;
  btnTitle?: string;
  btnCancel?: string;
  rowDirection?: boolean;
  open: boolean;
  closeAction?: (isOk: boolean, e?: any, isSystemClose?: boolean) => void;
}) => {
  return (
    <Modal
      // title=
      className={styles.modalContainer}
      open={open}
      onCancel={(e) => {
        if (closeAction) {
          closeAction(false, e, true);
        }
      }}
      footer={null}
      destroyOnClose={true}

    >
      <Form
        name="normal_edit_form"
        layout="vertical"
        className={styles.formContent}
        style={{ padding: '20px 24px' }}
        onClick={(e) => { e?.stopPropagation() }}
      >
        {title && (typeof title === 'string' ? <div className={styles.titleContainer}>{title}</div> : title)}

        {desc && (
          typeof desc === 'string' ? <Form.Item>
            <div className={styles.desc}>{desc}</div>
          </Form.Item> : desc
        )}

        {(btnTitle || btnCancel) && (
          <Form.Item>
            <div className={classNames([styles.btnContainer, !rowDirection && styles.columnDirection])}>
              {btnCancel && <Button
                className={styles.bottomCancelButton}
                onClick={(e) => {
                  if (closeAction) {
                    closeAction(false, e);
                  }
                }}
              >
                {btnCancel}
              </Button>}

              {btnTitle && <Button
                className={styles.bottomButton}
                onClick={(e) => {
                  if (closeAction) {
                    closeAction(true, e);
                  }
                }}
              >
                {btnTitle}
              </Button>}
            </div>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CommonSimpleModal;
